import { api } from '../utils/api';
/** 注册  */
export function register(data) {
    return api('/web/user/register', data, 'POST', false, true, true);
}

/** 登录  忘记密码*/
export function login(data) {
    return api('/web/user/login', data, 'POST', false, true, true);
}

// 获取验证码
export function getVerificationCode(data) {
    return api('/web/common/sms/code', data, 'POST', false, true, true);
}

// 忘记密码 -> 重置密码
export function findPassword(data) {
    return api('/web/user/find-pass', data, 'POST', false, true, true);
}

// 退出登录
export function logout(data) {
    return api('/web/user/login-out', data, 'POST', true);
}
