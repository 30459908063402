import "core-js/modules/es.array.push.js";
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-076289be"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "forget-password"
};
const _hoisted_2 = {
  key: 0,
  class: "reset-password-text"
};
const _hoisted_3 = {
  key: 1,
  class: "reset-password-text"
};
import { ref, computed, reactive } from 'vue';
import checkIcon from '../../../public/assets/login-check-icon.svg';
import closeIcon from '../../../public/assets/login-close-icon.svg';
import { login, findPassword, getVerificationCode } from '../../api/login';
import { showSuccessToast, showFailToast } from 'vant';
import 'vant/es/toast/style';
import { useRouter } from 'vue-router';
export default {
  __name: 'index',
  setup(__props) {
    const router = useRouter();
    // 手机号验证函数
    const phoneCodeValidator = val => {
      return /^1(3\d|4[579]|5[^4\D]|6[67]|7[^249\D]|8\d|9[189])\d{8}$/.test(val);
    };
    // 密码验证函数
    const passwordValidator = val => {
      return /^(?!.*\s).{6,20}$/.test(val);
    };
    // 确认密码验证函数
    const confirmPasswordValidator = val => {
      let flag = false;
      if (val === registerForm.password) {
        flag = true;
      }
      return flag;
    };
    // 验证码验证函数
    const codeValidator = val => {
      return /^\d{6}$/.test(val);
    };
    const type = ref(0); // 0: 验证手机  1: 重置密码

    // 验证手机 开始
    const messageRef = ref();
    const messageForm = reactive({
      tel: '',
      code: ''
    });
    const messageRules = {
      tel: [{
        required: true,
        message: '请输入手机号'
      }, {
        validator: phoneCodeValidator,
        message: '手机号格式错误'
      }],
      code: [{
        required: true,
        message: '请输入验证码'
      }, {
        validator: codeValidator,
        message: '验证码必须是6位数字'
      }]
    };
    const isMessageSubmitEnabled = computed(() => {
      let flag = true;
      if (phoneCodeValidator(messageForm.tel) && codeValidator(messageForm.code)) {
        flag = false;
      }
      return flag;
    });
    let messageTimer = null;
    let messageTime = 60;
    const getMessageCodeRef = ref('获取');
    const getMessageCode = () => {
      if (!phoneCodeValidator(messageForm.tel)) {
        return;
      }
      if (messageTimer != null) return;
      messageTime = 60;
      getMessageCodeRef.value = `${messageTime}s`;
      messageTimer = setInterval(() => {
        if (messageTime === 0) {
          clearInterval(messageTimer);
          getMessageCodeRef.value = '获取';
          messageTimer = null;
        } else {
          messageTime--;
          getMessageCodeRef.value = `${messageTime}s`;
        }
      }, 1000);
      const data = {
        phone: parseInt(messageForm.tel),
        type: 'update_pass'
      };
      getVerificationCode(data).then(() => {
        showSuccessToast({
          message: '验证码已发送',
          duration: 1000
        });
      }).catch(err => {
        showFailToast({
          message: err.data.msg,
          duration: 1000
        });
      });
    };
    const onNextStep = () => {
      const data = {
        bind_phone: messageForm.tel,
        method: 'bind_phone',
        code: messageForm.code
      };
      login(data).then(() => {
        type.value = 1;
      }).catch(() => {});
    };
    // 验证手机  结束

    // 重置密码 开始
    const registerRef = ref();
    const registerForm = reactive({
      password: '',
      confirmPassword: ''
    });
    const registerRules = {
      password: [{
        required: true,
        message: '请输入您的密码'
      }, {
        validator: passwordValidator,
        message: '6-20位 不能出现空格'
      }],
      confirmPassword: [{
        required: true,
        message: '请输入验证码'
      }, {
        validator: confirmPasswordValidator,
        message: '请再次确认密码，保持相同'
      }]
    };

    // 密码图标切换
    const passwordIcon = ref(closeIcon);
    const confirmPasswordIcon = ref(closeIcon);
    const isShowPassword = ref('password');
    const registerPasswordIcon = () => {
      if (passwordIcon.value === closeIcon) {
        isShowPassword.value = 'text';
        passwordIcon.value = checkIcon;
      } else {
        isShowPassword.value = 'password';
        passwordIcon.value = closeIcon;
      }
    };
    const isShowConfirmPassword = ref('password');
    const registerConfirmPasswordIcon = () => {
      if (confirmPasswordIcon.value === closeIcon) {
        isShowConfirmPassword.value = 'text';
        confirmPasswordIcon.value = checkIcon;
      } else {
        isShowConfirmPassword.value = 'password';
        confirmPasswordIcon.value = closeIcon;
      }
    };

    // 注册提交按钮样式切换
    const isRegisterSubmitEnabled = computed(() => {
      let flag = true;
      if (passwordValidator(registerForm.password) && confirmPasswordValidator(registerForm.confirmPassword) && registerForm.confirmPassword === registerForm.password) {
        flag = false;
      }
      return flag;
    });

    // 重置密码表单提交
    const onResetPasswordSubmit = () => {
      const data = {
        password: registerForm.password,
        bind_phone: messageForm.tel,
        code: messageForm.code
      };
      findPassword(data).then(() => {
        showSuccessToast({
          message: '密码重置成功',
          duration: 1000,
          onClose: () => {
            router.push('/login');
          }
        });
      });
    };
    // 重置密码 结束

    return (_ctx, _cache) => {
      const _component_van_field = _resolveComponent("van-field");
      const _component_van_cell_group = _resolveComponent("van-cell-group");
      const _component_van_button = _resolveComponent("van-button");
      const _component_van_form = _resolveComponent("van-form");
      const _component_van_icon = _resolveComponent("van-icon");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [type.value === 0 ? (_openBlock(), _createElementBlock("div", _hoisted_2, "请验证手机重置密码")) : _createCommentVNode("", true), type.value === 1 ? (_openBlock(), _createElementBlock("div", _hoisted_3, "请输入新密码")) : _createCommentVNode("", true), type.value === 0 ? (_openBlock(), _createBlock(_component_van_form, {
        key: 2,
        ref_key: "messageRef",
        ref: messageRef,
        class: "message-form",
        onSubmit: onNextStep
      }, {
        default: _withCtx(() => [_createVNode(_component_van_cell_group, {
          inset: ""
        }, {
          default: _withCtx(() => [_createVNode(_component_van_field, {
            clearable: true,
            "error-message": "",
            autocomplete: "",
            maxlength: 11,
            modelValue: messageForm.tel,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => messageForm.tel = $event),
            type: "tel",
            name: "tel",
            placeholder: "请输入手机号",
            rules: messageRules.tel
          }, null, 8, ["modelValue", "rules"])]),
          _: 1
        }), _createVNode(_component_van_cell_group, {
          style: {
            "position": "relative"
          },
          inset: ""
        }, {
          default: _withCtx(() => [_createVNode(_component_van_field, {
            "error-message": "",
            autocomplete: "",
            maxlength: 6,
            modelValue: messageForm.code,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => messageForm.code = $event),
            name: "code",
            placeholder: "请输入短信验证码",
            type: "text",
            rules: messageRules.code
          }, null, 8, ["modelValue", "rules"]), _createElementVNode("div", {
            class: _normalizeClass(["get-message-code", [phoneCodeValidator(messageForm.tel) ? 'active-text' : '']]),
            onClick: getMessageCode
          }, _toDisplayString(getMessageCodeRef.value), 3)]),
          _: 1
        }), _createVNode(_component_van_button, {
          class: _normalizeClass([!isMessageSubmitEnabled.value ? 'active-btn' : '']),
          disabled: isMessageSubmitEnabled.value,
          round: "",
          block: "",
          type: "primary",
          "native-type": "submit"
        }, {
          default: _withCtx(() => [_createTextVNode(" 下一步 ")]),
          _: 1
        }, 8, ["class", "disabled"])]),
        _: 1
      }, 512)) : _createCommentVNode("", true), type.value === 1 ? (_openBlock(), _createBlock(_component_van_form, {
        key: 3,
        ref_key: "registerRef",
        ref: registerRef,
        class: "register-form",
        onSubmit: onResetPasswordSubmit
      }, {
        default: _withCtx(() => [_createVNode(_component_van_cell_group, {
          style: {
            "position": "relative"
          },
          inset: ""
        }, {
          default: _withCtx(() => [_createVNode(_component_van_field, {
            "error-message": "",
            autocomplete: "",
            maxlength: 20,
            modelValue: registerForm.password,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => registerForm.password = $event),
            name: "password",
            placeholder: "请输入密码",
            type: isShowPassword.value,
            rules: registerRules.password
          }, null, 8, ["modelValue", "type", "rules"]), _createVNode(_component_van_icon, {
            class: "icon",
            name: passwordIcon.value,
            onClick: registerPasswordIcon
          }, null, 8, ["name"])]),
          _: 1
        }), _createVNode(_component_van_cell_group, {
          style: {
            "position": "relative"
          },
          inset: ""
        }, {
          default: _withCtx(() => [_createVNode(_component_van_field, {
            "error-message": "",
            autocomplete: "",
            maxlength: 20,
            modelValue: registerForm.confirmPassword,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => registerForm.confirmPassword = $event),
            name: "confirmPassword",
            placeholder: "请再次确认密码，保持相同",
            type: isShowConfirmPassword.value,
            rules: registerRules.confirmPassword
          }, null, 8, ["modelValue", "type", "rules"]), _createVNode(_component_van_icon, {
            class: "icon",
            name: confirmPasswordIcon.value,
            onClick: registerConfirmPasswordIcon
          }, null, 8, ["name"])]),
          _: 1
        }), _createVNode(_component_van_button, {
          class: _normalizeClass([!isRegisterSubmitEnabled.value ? 'active-btn' : '']),
          disabled: isRegisterSubmitEnabled.value,
          round: "",
          block: "",
          type: "primary",
          "native-type": "submit"
        }, {
          default: _withCtx(() => [_createTextVNode(" 确定 ")]),
          _: 1
        }, 8, ["class", "disabled"])]),
        _: 1
      }, 512)) : _createCommentVNode("", true)]);
    };
  }
};